import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import imgEngineeredby_Imagetext_01 from '../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_01.jpg'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../assets/mark.png'
import HFTabs from '../../Components/HfTabs/HfTabs';
import imgHydraulicinstallation from '../../assets/Products/Hydraulic Installation/Hydraulicinstallation.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard'
import imgPipeline from '../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard1.jpg'
import imgHydraulicInstallation from '../../assets/Products/Hydraulic Installation/Hydraulicinstallation.jpg'
import imgSellComponent from '../../assets/Services/CounterAnd Sell Component/1111.jpg'
import imgCylinderRepair from '../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Stage.jpg'
import imgStudyDesignMaintainance from '../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'


const Hydraulicinstallation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Expert Consultation",
            description: "Our team of specialists is here to guide you through every step of the process.",
            image: imgMark
        },
        {
            title: "High-Quality Standards",
            description: "We use only the best materials and practices to ensure durability and reliability.",
            image: imgMark
        },
        {
            title: "Tailored Solutions",
            description: "We customize each installation to fit your specific needs and preferences.",
            image: imgMark
        },
        {
            title: "Ongoing Support",
            description: "Our relationship doesn’t end with installation; we’re here for you whenever you need assistance.",
            image: imgMark
        },
    ]

    const imageTextData = [
        { image: imgHydraulicinstallation, title: "Design and Planning", description: "Once we have a clear understanding of your needs, our team creates a detailed design. This design can be schematized using AutoCAD, ensuring precision in every aspect. If mechanical drawings are necessary, we utilize Inventor to produce accurate and comprehensive plans. This meticulous planning phase is crucial for the success of the project." },
        { image: imgHydraulicinstallation, title: "Construction in Our Workshop", description: "With the designs finalized, we move to the construction phase in our state-of-the-art workshop, equipped with the necessary machinery to bring your hydraulic systems to life. Our skilled technicians ensure that every component is crafted to the highest standards of quality and safety." },
        { image: imgHydraulicinstallation, title: "Finishing Touches", description: "Once the installation is complete, it is painted in your chosen RAL color, providing a professional finish that aligns with your branding and aesthetic preferences. After painting, the installation undergoes rigorous testing in our workshop to ensure it meets all operational requirements and safety standards." },
        { image: imgHydraulicinstallation, title: "Seamless Installation and Support", description: "After successful testing, the installation is carefully transported to your location. Our team will assist with the placement, connection, and startup of the system, ensuring everything operates smoothly. We conduct thorough test runs to verify that the installation functions as intended." },
        { image: imgHydraulicinstallation, title: "Commitment to Safety and Efficiency", description: "Throughout the entire process, we adhere to general machine guidelines, closely monitoring the safe operation of the installation. We are also committed to energy efficiency, ensuring that your hydraulic system operates in the most sustainable manner possible." },
    ]

    const tabTitles = [
        { title: "Design and Planning", eventKey: "Design and Planning" },
        { title: "Construction", eventKey: "Construction" },
        { title: "Finishing", eventKey: "Finishing" },
        { title: "Installation and Support", eventKey: "Installation and Support" },
        { title: "Safety and Efficiency", eventKey: "Safety and Efficiency" },
    ]

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHydraulicinstallation}
                    title={"Expert Hydraulic Installations Tailored to Your Needs"}
                    paragraph={"Delivering Customized Hydraulic Solutions with Precision Engineering, Comprehensive Testing, and Ongoing Support to Ensure Maximum Efficiency and Safety for Your Unique Applications."}
                />
            </div>

            {/* <div>
                    <SectionHeader title={"Tailored Hydraulic Installations for Your Unique Needs"} subtitle={"At L.I.S.-Hydraulics, we believe that every hydraulic installation is a unique project that should be tailored to meet the specific requirements of our customers. Our process begins with a collaborative discussion where we work closely with you to understand the intended purpose of the installation, its required functionality, aesthetic preferences, and how it will integrate into your existing systems."} />
                    <HFTabs tabTitles={tabTitles} imageTextData={imageTextData} />
                </div> */}

            <div className=''>
                <SectionHeader title={"Our Expertise in Hydraulic Installations"} paragraph={"At L.I.S.-Hydraulics, we specialize in designing, building, and commissioning complete hydraulic systems tailored to meet the unique needs of our clients. Our experienced team collaborates closely with you to understand the specific requirements of your installation, ensuring optimal functionality and integration into your existing systems."} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPipeline}
                                learnMoreLink={"/pipelines"}
                                title="Piping"
                                desc={"The L.I.S.-Hydraulics range of pipeline products includes expansion joints, bent pipes and special fittings for liquids, gases and coolant water. L.I.S.-Hydraulics state-of-the-art manufacturing technologies ensure absolute precision, the highest quality and rapid production to meet the customer’s specifications exactly."} /></Col>

                            {/* <Col sm={12} md={6} lg={4} ><ImageCard image={imgHydraulicInstallation}
                                learnMoreLink={"/Hydraulicinstallation"}
                                title="Hydraulic Installation"
                                desc={"Delivering Customized Hydraulic Solutions with Precision Engineering, Comprehensive Testing, and Ongoing Support to Ensure Maximum Efficiency and Safety for Your Unique Applications."} /></Col> */}

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSellComponent}
                                learnMoreLink={"/Sellcomponents"}
                                title="Sell Components"
                                desc={"HANSA‑FLEX always has the perfect sealing solution. More than 8,000 standard seals available from stock and an in-house manufacturing centre for special seals."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCylinderRepair}
                                learnMoreLink={"/hydraulic-cyclinder-repair"}
                                title="Hydraulic Cylinder"
                                desc={"Hydraulic cylinders are subject to extreme stresses due to their continual extending and retracting. The result is wear, which puts the whole working process at risk of failure. Overhauling a cylinder is a good alternative to buying a new one. The L.I.S.-Hydraulics cylinder repair centre repairs hydraulic cylinders of all sizes and manufacturer, hydraulic pumps, valves, power units and motors rapidly and with minimum downtime."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgStudyDesignMaintainance}
                                learnMoreLink={"/StudyDesignandMaintenace"}
                                title="Study, Design and Maintainance"
                                desc={"L.I.S.-Hydraulics assists customers in developing new hydraulic projects or converting existing installations. By translating the application into technical specifications, L.I.S.-Hydraulics helps to make the right choice of the necessary components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHydraulicInstallation}
                                learnMoreLink={"/Countersales"}
                                title="Counter Sales"
                                desc={"L.I.S.-Hydraulics is always available at the counter with advice and action."} /></Col>

                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default Hydraulicinstallation