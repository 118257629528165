import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgMark from '../../../assets/mark.png'

import ImageText from '../../../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import imgEngineeredby_Centerspread from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'
import imgHydraulicInstallation from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Stage.jpg'
import imgMaintainance from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Stage.jpg'
import imgFluid from '../../../assets/Services/fluidService.jpg'
import imgMobileHydrailic from '../../../assets/Services/MobileService/MobileService.jpg'
import imgCylinder from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Stage.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgConsultation from '../../../assets/Services/Study, Design and Maintainance/consultation.png'
import imgDesign from '../../../assets/Services/Study, Design and Maintainance/Design.png'
import imgImplementation from '../../../assets/Services/Study, Design and Maintainance/Implementation.png'
import imgSupport from '../../../assets/Services/Study, Design and Maintainance/support.svg'

const StudyDesignandMaintenace = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Engineeredby_Imagetext_01 = {
        // image: imgEngineeredby_Imagetext_01,
        title: 'Solutions and service:',
        listItems: ['custom design', 'manufacturing and assembly of hydraulic systems and cylinders', 'customer specific maintence concepts', 'oil management and filtration', 'detecting and solving hydraulic leaks', 'checking and topping up battery systems', 'fast mobile repair service on locatioon or in our own workshop,', 'renovation of hyfraulic systems and cylinders'],
    }

    const featuresData = [
        {
            title: "Consultation",
            description: "Engaging with clients to gather insights and requirements.",
            image: imgConsultation
        },
        {
            title: "Design",
            description: "Creating detailed technical specifications and designs tailored to the project.",
            image: imgDesign
        },
        {
            title: "Implementation",
            description: "Overseeing the assembly and installation of hydraulic systems.",
            image: imgImplementation
        },
        {
            title: "Support",
            description: "Providing ongoing maintenance and support to ensure optimal performance.",
            image: imgSupport
        }
        // Add more objects for additional FeaturesCard data
    ];




    return (
        <div>

            <CenterStage
                imageSrc={imgEngineeredby_Centerspread}
                title={"STUDY, DESIGN AND MAINTAINANCE"}
                paragraph={"L.I.S.-Hydraulics assists customers in developing new hydraulic projects or converting existing installations. By translating the application into technical specifications, L.I.S.-Hydraulics helps to make the right choice of the necessary components. The design of the project will be built up step by step in consultation with the customer before a targeted offer is made. L.I.S.-Hydraulics will closely monitor that the installation is assembled in the most sustainable, energy-saving and efficient way possible."}
            />

            <div>
                <SectionHeader title={"Our Approach"} subtitle={"Our approach is centered around collaboration and innovation. We work closely with our clients to understand their specific requirements and challenges. By leveraging our extensive knowledge and experience, we translate these needs into effective hydraulic solutions. Our process includes:"} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader title={"Solutions and services"} subtitle={"L.I.S.-Hydraulics offers a range of solutions and services, including:"} />
                <Container className="custom-container">
                    <Row>
                        {/* <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgEngineeredby_Centerspread} learnMoreLink="/hydraulic-hoses" title="Custom design" desc="Tailored hydraulic system designs to meet specific needs." />
                        </Col> */}
                        <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgHydraulicInstallation} learnMoreLink="/installation-of-pipe-and-hoseline-systems" title="Manufacture and assembly of hydraulic systems and cylinders" desc="Production and assembly of hydraulic systems and cylinders." />
                        </Col>
                        <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgMaintainance} learnMoreLink="/preventative-maintainance" title="Customer specific maintenance concepts" desc="Maintenance strategies tailored to individual customer requirements." />
                        </Col>
                        <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgFluid} learnMoreLink="/fluid-service" title="Oil management and filtration" desc="Efficient management and filtration of hydraulic oils." />
                        </Col>
                        {/* <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgEngineeredby_Centerspread} learnMoreLink="/" title="Detecting and solving hydraulic leaks" desc="Identifying and solving hydraulic leaks effectively." />
                        </Col> */}
                        {/* <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgEngineeredby_Centerspread} learnMoreLink="/" title="Checking and topping up battery systems" desc="Checking and topping up battery systems as needed." />
                        </Col> */}
                        <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgMobileHydrailic} learnMoreLink="/mobile-rapid-hydraulic-service" title="Fast mobile repair service on location or in our own workshop" desc="Quick repair services available on-site or in our workshop." />
                        </Col>
                        <Col sm={12} md={6} lg={4} >
                            <ImageCard image={imgCylinder} learnMoreLink="/hydraulic-cyclinder-repair" title="Renovation of hydraulic systems and cylinders" desc="Comprehensive renovation services for hydraulic systems and cylinders." />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* data-aos={isMobile ? "fade-up" : "fade-right"} */}
        </div>
    )
}

export default StudyDesignandMaintenace