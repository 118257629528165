import PropTypes from 'prop-types';
import './FeaturesCard.css'; // Assuming you have a CSS file to style the card

function FeaturesCard({ title, description, image, link }) {
  // Determine target based on the link if it exists
  const target = link && link.includes('/') ? '_self' : '_blank';
  const rel = target === '_blank' ? 'noreferrer noopener' : undefined;

  const content = (
    <>
      <img src={image} alt={title} className="features-card-image" />
      <div className="features-card-content">
        <h3 className="features-card-title">{title}</h3>
        <p className="features-card-description">{description}</p>
      </div>
    </>
  );

  return link ? (
    <a href={link} target={target} rel={rel} className="features-card" style={{ textDecoration: 'none', color: '#212529' }}>
      {content}
    </a>
  ) : (
    <div className="features-card" style={{ textDecoration: 'none', color: '#212529' }}>
      {content}
    </div>
  );
}

export default FeaturesCard;
