import React, { useEffect } from 'react'
import imgNewTraining from '../../assets/imageTraining.jpg'
import CenterStage from '../../Components/CenterStage/CenterStage'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import img1new from '../../assets/1new.png'
import img2new from '../../assets/2new.png'
import img3new from '../../assets/3new.png'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import TwoColumnPoints from '../../Components/TwoColumnPoints/TwoColumnPoints'
import TextCaption from '../../Components/TextCaption/TextCaption'
import imageTraining2 from '../../assets/imageTraining2.jpg'
import Promoter from '../../Components/Promoter/Promoter'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgTrainingPoster from '../../assets/Training/LISTraining.jpg'



const Training = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const featuresData = [
    {
      title: "Comprehensive Curriculum",
      description: "Dive into hydraulic systems, covering diagrams, Pascal's Law, and fluid mechanics.",
      image: img1new
    },
    {
      title: "Hands-On Practice",
      description: "Engage in practical exercises with pumps, valves, and flow control techniques to reinforce your learning.",
      image: img2new
    },
    {
      title: "Certification Achievement",
      description: "Complete the program and receive a certificate to validate your new skills and knowledge.",
      image: img3new
    }
  ]

  const promoterTitle = 'Accelerate your career';
  const promoterChecklistItems = [
    'Come alone or with your team to follow this 3-day training.',
    <>The next date is <span style={{ fontWeight: '700' }}>February 19 – 21, 2025.</span></>,
    'Max. 8 participants per 3-day training, to keep it as practical as possible!',
    <>Interested? Register? More information? Ask your question to <a href="mailto:sarah@hansa-flex.be"> sarah@hansa-flex.be</a>.</>
  ];
  // const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];


  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imageTraining2}
          title="Unlock Your Potential in Hydraulics!"
          paragraph="Join us for an intensive 3 days training program designed to enhance your skills. Complete the training and earn your certificate to showcase your achievement! " />
      </div>

      <div className='div-gray' style={{paddingBottom:'20px'}}>
        <TextCaption
          version={2}
          title={"Why Choose Our Training Programs?"} // Updated title for clarity and impact
          bulletPoints={["Training employees is a current and essential topic, especially in the rapidly changing world of technology.",
            "The continuous innovations in drive and control systems and their components require employees who are always up to date with the latest technical developments.",
            "Only with well-qualified personnel can companies respond to changes in the economy in a customer-oriented manner and navigate through uncertain times."]}
        />
      </div>

      {/* <div className='div-gray' style={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', padding:'30px' }}>
        <SectionHeader title={"Unlock Your Potential in Hydraulics!"} 
        subtitle={"Gain hands-on experience with real-world hydraulic systems. Learn from industry experts and stay ahead in your field."}/>
        <img src={imgTrainingPoster} alt="Training Poster" style={{ maxWidth: '30%', height: 'auto' }} />
      </div> */}

      <div>
        <SectionHeader
          title="Accelerate your career"
          subtitle="Gain hands-on experience with real-world hydraulic systems. Learn from industry experts and stay ahead in your field. Discover the key components of our comprehensive training program."
        />
        <FeatureCardCarousel
          featuresData={featuresData}
        />
      </div>

      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            // btnLabel1={"Find Out More !"}
            // btnLabel1Link={"https://shop.hansa-flex.be/nl_NL/slangen/c/webcat_HF_SCH"}
            imageUrl={imgTrainingPoster}
          />
          // TODO: contact us button
        )}
      </div>

      {/* <div>
        <TwoColumnPoints title={"Training Content Highlights"}
          points={[
            "Structure and construction of a hydraulic diagram",
            "Basic concepts including Pascal's Law",
            "Fluids to be applied",
            "Fixed and adjustable pumps",
            "Pilot valves, safety valves, throttling and flow control valves",
            "Filtration technology",
            "Troubleshooting and interpretation"
          ]} />
      </div> */}

      {/* <TextCaption
        paragraph="Prospects after the Training - Your Start into the Future"
        bulletPoints={[
          "Go directly into employment! - We take over more than 90% of our trained apprentices into employment.,",
          "Get in wherever you want! - Whether in the head office, the branches or the production areas - at L.I.S.-Hydraulics there are a wide range of opportunities for your first job at home and abroad.",
          "Work abroad for a while! - With the L.I.S.-Hydraulics Year of Wandering we make it possible for you to work in one of our foreign branches.",
          "Become a hydraulic specialist! - If you have a strong interest in technology and craftsmanship, we can offer you further training to become a hydraulics specialist with a recognised HWK qualification.",
          "Educate yourself further! - We support your development even after your training. L.I.S.-Hydraulics is particularly keen to promote specialist further and advanced training.",
        ]}
      /> */}

      <br /><br /><br />

    </div>

  )
}

export default Training