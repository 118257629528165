import React from 'react'
import TextCaption from '../Components/TextCaption/TextCaption';
import { Link } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
const Imprint = () => {
  return (
    <div>
<TextCaption
                title=""
            />

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Zum Panrepel 44</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>28307 Bremen</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Germany</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Tel.: +49-421-489070</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Fax: +49-421-4890748</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>E-Mail: infohansa-flexcom</p>
            </div>


            <TextCaption
                title=""
            />

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Executive Board:</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Thomas Armerding, Uwe Buschmann, Christian-Hans Bültemeier</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Chairman of the Supervisory Board: Tim Hollweg</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HRB Bremen 26530 HB; Registered office Bremen</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Sales tax identification number: DE170352164</p>

                <br />
                <li> <a href="https://www.hansa-flex.de/agb" target="_blank" rel="noopener noreferrer" className="footer-link"> Allgemeine Geschäftsbedingungen (de)</a></li>
                <li> <a href="https://www.hansa-flex.de/einkauf-und-logistik/allgemeine-einkaufsbedingungen" target="_blank" rel="noopener noreferrer" className="footer-link">Sales and delivery conditions (en)</a></li>
            </div>
<br />




            
    </div>



  )
}

export default Imprint