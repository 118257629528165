import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgStage from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Stage.jpg'
import imgHydraulicPumps from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HydraulicPumps.jpg'
import imgHighPerformanceHydraulicHose from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HighPerformanceHydraulicHose.jpg'
import imgOverhaulOfHydraulic from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/OverhaulOfHydraulic.jpg'
import imgAnalogueAndDigital from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AnalogueAndDigital.jpg'
import imgCartridgeAndCetop from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CartridgeAndCetop.jpg'
import imgMeasuringTech from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/MeasuringTech.jpg'
import imgHighPressureHydraulics from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HighPressureHydraulics.jpg'
import imgPressureAccumulatorsimg from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/PressureAccumulators.jpg'
import imgCustomerSolution from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CustomerSolution.jpg'
import imgTanks from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Tanks.jpg'
import imgElectricMotor from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/ElectricMotor.jpg'
import imgHeatExchangers from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HeatExchangers.jpg'
import imgGearFlowDividers from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/GearFlowDividers.jpg'
import imgAccessories from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Accessories.jpg'
import imgDesignOfHydraulicUnit from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/DesignOfHydraulicUnit.jpg'
import imgAdviceAndAssistance from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AdviceAndAssistance.jpg'
import imgAlwaysThereForYou from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AlwaysThereForYou.jpg'
import imgPerfectHoseLine from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/PerfectHoseLine.jpg'
import imgBentPipelines from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/BentPipelines.jpg'
import imgCertifiedSafety from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CertifiedSafety.jpg'

const DriveAndControlTechnology = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const DriveAndControlTechnology1 = {
        image: imgCustomerSolution,
        listItems: ["Development of customer-specific special parts", "Extensive advice and assistance in prototype building", "Designed using 3D models", "Preassembly of hose lines and pipelines", "Series manufacture of components and component groups", "Design and manufacture of hydraulic units"]
    }

    const DriveAndControlTechnology2 = {
        image: imgDesignOfHydraulicUnit,
        title: 'Design of hydraulic units and development of special solutions',
        description: 'Consultation with our experts is a two-way process in which we develop the perfect solution for your application with you and support you from design to production. Your advantages from solutions developed with L.I.S.-Hydraulics:',
        listItems: ["Development of customer-specific special parts", "Short-notice order call-offs", "Extensive inspection and testing"],
    }

    const DriveAndControlTechnology3 = {
        image: imgAdviceAndAssistance,
        title: 'Advice and assistance from experienced experts',
        description: 'The L.I.S.-Hydraulics Industrial Service can advise and assist you with all the tasks required on your systems:',
        listItems: ["Installation and assembly", "Servicing and maintenance", "Optimising, updating and retrofitting"],
        buttonName: "READ MORE"
    }

    const DriveAndControlTechnology4 = {
        image: imgAlwaysThereForYou,
        title: 'The always-there-for-you service',
        description: 'With around 200 service vehicles across Germany, our mobile Rapid Hydraulic Service is always there for you, available 24/7 and 365 days a year to minimise your stoppage times.',
        listItems: ["Mobile workshops on wheels, replacement part manufactured directly on site", "Extensive range of replacement parts on board", "Own independent power supply"],
        buttonName: "READ MORE"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Proven quality",
            description: "All hydraulic components supplied by L.I.S.-Hydraulics come from well-known manufacturers.",
            image: imgMark
        },
        {
            title: "Rapid availability",
            description: "You receive your products directly from the warehouse. Specially manufactured products are delivered as soon as possible.",
            image: imgMark
        },
        {
            title: "Sound advice",
            description: "Advice from fluid experts on all questions about selection, design and maintenance.",
            image: imgMark
        },
        {
            title: "Comprehensive services",
            description: "Our technicians help and assist you in the assembly, maintenance and optimisation of your systems",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Electric motors in industrial quality",
            description: "L.I.S.-Hydraulics supplies DC motors (12, 24 and 48 V) for compact power units with 0.5 to 3.0 kW output and AC / three-phase motors, in types B3/B14, B3/B5 and B5 and energy efficiency classes (IE2, IE3) with 0.54 to 63 kW output. Other variants with special voltages, higher outputs, frequency inverters and in other energy-efficiency classes are available on request.",
            buttonName: "ELECTRIC MOTORS",
            image: imgElectricMotor
        },
        {
            title: "Hydraulic tanks for efficient fluid storage",
            description: "L.I.S.-Hydraulics supplies tanks made from cast aluminium, welded or deep-drawn steel sheet with steel covers and seals. Our unit manufacturing centre can also manufacture other variants to suit your specific requirements.",
            listItems: ["Aluminium tanks: effective volume 3.0 l to 123 l", "Steel tanks: effective volume 6.0 l to 375 l", "Extensive range of accessories: fluid level indicators, sealed covers, filler filters and tank heaters"],
            buttonName: "ALL HYDRAULIC TANKS",
            image: imgTanks
        },
        {
            title: "Heat exchangers for efficient temperature regulati",
            description: "Heat exchangers keep fluids at the optimum temperature. We supply oil-air coolers directly from stock and variants such as coolers with hydraulically driven fans or plate heat exchangers on request.",
            listItems: ["Electric fan drive 230V / 400V", "Cooling capacity 0.02 kW to 1.1 kW", "Thermostats available as accessories"],
            buttonName: "HEAT EXCHANGERS",
            image: imgHeatExchangers
        },
        {
            title: "Hydraulic gear flow dividers with high division ac",
            description: "Gear flow dividers allow motors and cylinders to be operated synchronously. The L.I.S.-Hydraulics online shop has gear flow dividers optionally available with pressure limiting and phase correction valves.",
            listItems: ["In sizes 0 to 2", "2-way and 4-way versions", "Flow per element 0.7 l/min to 25.9 l/min"],
            buttonName: "ALL GEAR FLOW DIVIDERS",
            image: imgGearFlowDividers
        },
        {
            title: "Pump mounts, couplings and damping elements",
            description: "As a system provider, we can also supply suitable components for connecting electric motors and pumps. We supply pump mounts and couplings in steel or aluminium for a wide range of applications. Pump mounts and couplings for gear pumps and axial piston pumps from our product list are supplied directly from stock, other versions for other pump types can be supplied on request. We always hold sprockets for couplings as replaceable parts in stock for you. Our online shop has a large selection of damping rails and rings available for low-vibration attachment of electric motors and pump mounts on hydraulic power units.",
            image: imgAccessories
        },
    ];

    const tabTitles = [
        { title: "Electric motors", eventKey: "Electric motors" },
        { title: "Tanks", eventKey: "Tanks" },
        { title: "Heat exchangers", eventKey: "Heat exchangers" },
        { title: "Gear flow dividers", eventKey: "Gear flow dividers" },
        { title: "Accessories", eventKey: "Accessories" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"DRIVE AND CONTROL TECHNOLOGY FROM L.I.S.-Hydraulics"}
                    paragraph={"Hydraulic components fulfil the highest quality standards at L.I.S.-Hydraulics, where quality management has DIN ISO 9001 certification. Availability is ensured by our warehouse, which stocks over 4,500 hydraulic components. We even have a special selection of components for the 700 bar range. Hydraulic systems are increasingly networked with electronic controls. Consequently, L.I.S.-Hydraulics’s product portfolio also includes components from the fields of proportional valve technology, sensors and metrology."}
                />
            </div>

            <SectionHeader
                title={"HYDRAULIC COMPONENTS FROM THE FLUID SPECIALISTS"}
                subtitle={"L.I.S.-Hydraulics offers a wide range of hydraulic components from well-known manufacturers and can produce its own special versions in-house for you."}
            />
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgHydraulicPumps}
                            title="Hydraulic pumps for all types of applications"
                            desc={"The L.I.S.-Hydraulics range of products includes gear, axial piston, radial piston and vane pumps for industrial and mobile hydraulics."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighPerformanceHydraulicHose}
                            title="High-performance hydraulic motors"
                            desc={"Take a look at our extensive portfolio of geroller and gerotor motors available as premium or cost-effective economy variants in our customary proven quality."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgOverhaulOfHydraulic}
                            title="Sale, manufacture and overhaul of hydraulic cylinders"
                            desc={"L.I.S.-Hydraulics sells a wide range of single- and double-acting standard cylinders, manufactures hydraulic cylinders with strokes up to 8,000 mm and provides..."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgAnalogueAndDigital}
                            title="Analogue and digital switches and sensors"
                            desc={"L.I.S.-Hydraulics is a one-stop partner supplying a large selection of durable pressure, temperature and level switches and sensors for hydraulic systems."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCartridgeAndCetop}
                            title="Cartridge, CETOP and pipeline installation valves"
                            desc={"We offer you a large selection of directional, shut-off and pressure control valves, flow and proportional valves with various control types for pressures up to 350 bar."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgMeasuringTech}
                            title="Measuring technology and display instruments"
                            desc={"Our online shop has a wide range of measuring technology products to determine and monitor pressure, temperature and volumetric flow in hydraulic systems."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighPressureHydraulics}
                            title="High-pressure hydraulics up to 700 bar"
                            desc={"L.I.S.-Hydraulics has a special selection of components for the 700 bar operating range."} /></Col>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgPressureAccumulatorsimg}
                            title="Hydraulic pressure accumulators"
                            desc={"In addition to supplying diaphragm and bladder accumulators from well-known manufacturers and suitable accessories, we support you in your servicing, testing and..."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={'DEVELOPMENT OF CUSTOMER-SPECIFIC SPECIAL SOLUTIONS'}
                    subtitle={"We manufacture special solutions to meet your precise requirements. Our experts believe giving advice involves both talking and listening, followed by support for you in everything from design to manufacture."}
                />
                <ImageText
                    data={DriveAndControlTechnology1} />
            </div>

            <div>
                <SectionHeader
                    title={"OUR WAREHOUSES ALWAYS STOCK AROUND 4,500 PRODUCTS IN PROVEN L.I.S.-Hydraulics QUALITY"}
                    subtitle={"All drive and control technology components supplied by L.I.S.-Hydraulics fulfil the highest quality standards, are readily available in full product ranges and delivered quickly."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"OTHER HYDRAULIC UNIT COMPONENTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"KANBAN DELIVERY FROM L.I.S.-Hydraulics"}
                    subtitle={"We supply our goods to fit in with our customers’ logistics and production processes. With Kanban delivery based on the pull principle from L.I.S.-Hydraulics, you gain from Just-In-Time delivery, precisely when you need it, on request directly into your production line."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SERVICES FOR YOUR HYDRAULIC SYSTEMS FROM THE FLUID EXPERTS AT L.I.S.-Hydraulics"}
                    subtitle={"As a versatile and widely experienced system partner, we provide practical advice and support to our customers in all areas of hydraulics."}
                />
                <ImageText
                    data={DriveAndControlTechnology2} />

                <ImageText
                    data={DriveAndControlTechnology3}
                    left={false} />
                <ImageText
                    data={DriveAndControlTechnology4} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPerfectHoseLine}
                                title="The perfect hose line for every application"
                                desc={"L.I.S.-Hydraulics manufactures high-quality hydraulic and industrial hose lines for the transport of fluids, chemicals, foodstuff, air, water and abrasive media."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgBentPipelines}
                                title="Bent pipelines with various surface treatments"
                                desc={"Gain from our many years of experience in bending, forming, swaging, narrowing and flanging steel, stainless steel, copper and aluminium pipelines."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCertifiedSafety}
                                title="Certified safety and reliability"
                                desc={"Authorised personnel perform tests in-house or at your premises including documentation of the tests and results."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default DriveAndControlTechnology