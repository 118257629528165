import React, { useEffect } from 'react'
import CenterStage from '../Components/CenterStage/CenterStage'
import imgEngineeredby_Centerspread from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'
import SectionHeader from '../Components/SectionHeader/SectionHeader'
import imgEngineeredby_Imagetext_01 from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_01.jpg'
import imgEngineeredby_Imagetext_02 from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_02.jpg'
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../assets/mark.png'
import HFTabs from '../Components/HfTabs/HfTabs';

import ImageText from '../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../Components/ImageCard/ImageCard'
import imgEngineeredby_Imagecard_01 from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_01.jpg'
import imgEngineeredby_Imagecard_02 from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_02.jpg'
import imgEngineeredby_Imagecard_03 from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_03.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgSellcomponents from '../assets/Services/CounterAnd Sell Component/1111.jpg'
import imgBosch from '../assets/PartnerCompany/Keyvisual_moveto_471 (2).png'
import imgAventics from '../assets/PartnerCompany/AVENTICS_SP_RGB_EN2.png'
import imgGates from '../assets/PartnerCompany/Gates1 (1).png'
import imgVoss from '../assets/PartnerCompany/Voss (1)2.jpg'
import imgStauff from '../assets/PartnerCompany/stauff2.jpg'
import imgHF from '../assets/PartnerCompany/HFLogo2.jpg'

import imgHydraulicInstallation from '../assets/Products/Hydraulic Installation/Hydraulicinstallation.jpg'
import imgCounterSales from '../assets/Products/CounterSales.jpg'
import imgCylinderRepair from '../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Stage.jpg'
import imgStudyDesignMaintainance from '../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'
import ImageDescription from '../Components/ImageDescription/ImageDescription'
import imgSellComponent from '../assets/Services/CounterAnd Sell Component/1111.jpg'
import imgIndustrialHydraulics from '../assets/Services/CounterAnd Sell Component/Untitled Project.jpg'
import imgMobileHydraulics from '../assets/Services/CounterAnd Sell Component/Untitled Project (1).jpg'
import imgPneumatics from '../assets/Services/CounterAnd Sell Component/Untitled Project (4).jpg'
import imgCompactHydraulics from '../assets/Services/CounterAnd Sell Component/Web_Photo_Editor.jpg'
import imgSpareparts from '../assets/Services/CounterAnd Sell Component/Untitled Project (3).jpg'
import imgpipeComponents from '../assets/Services/CounterAnd Sell Component/Untitled Project (2).jpg'


const Sellcomponents = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const cylinder1 = {
        image: imgEngineeredby_Imagetext_01,
        title: 'Order standard cylinders now from the HANSA‑FLEX online shop',
        description: 'Our online shop has numerous single- and double-acting plunger cylinders up to 200 bar with standardised dimensions and, if required, mounting elements on the rod and base.',
        listItems: ["Permissible temperature: -15 °C to +80 °C", "Various sealing materials (NBR, Viton, PTFE)", "High-strength steel piston rod (200 h resistance in NSS test)"],
    }

    const cylinder2 = {
        image: imgEngineeredby_Imagetext_01,
        title: 'Customer-specific manufacture of new cylinders',
        description: 'HANSA‑FLEX manufactures hydraulic cylinders as replacement parts, prototypes or new product lines to your individual requirements as one-off, small batch or series manufactured items. The cylinders, which are designed for the intended application and use standardised components, provide an attractive price-performance ratio combined with easy procurement of spare parts.',
        listItems: ["Various pressure ranges up to max. 350 bar", "Comprehensive advice, creation of 3D models", "Customer-specific materials, surface treatments and paint finishes"],
    }

    const cylinder3 = {
        image: imgEngineeredby_Imagetext_01,
        title: 'Hydraulic cylinders with integrated displacement measuring systems',
        description: 'We equip cylinders with displacement transducer systems for precise positioning, actuating and control movements. A continuous position signal supplies precise real-time data about the cylinder movement.',
        listItems: ["Precise reporting of the cylinder position and speed", "Displacement measuring systems sourced from a renowned manufacturer", "Wide choice of sensor type (including digital, analogue, capacitive)", "Cylinders of any size can be manufactured", "Reliable signal generation under all conditions"],
    }

    const cylinder4 = {
        image: imgEngineeredby_Imagetext_01,
        title: 'Hydraulic cylinders with inductive limit switches',
        description: 'Position switches are a simple and cost-effective alternative to linear displacement transducers where the movement of a piston rod needs to be determined. Inductive, pressure-resistant proximity switches are attached in the end position of the hydraulic cylinder. If the piston reaches one of the two end positions, the relevant proximity switch transmits an electronic signal.',
        listItems: ["Reliable and precise position determination", "Contactless, wear-free switching", "Precise end position polling and high repeat accuracy"],
    }

    const cylinder5 = {
        image: imgEngineeredby_Imagetext_01,
        title: 'Hydraulic cylinders with valve technology',
        description: 'Protect people and machines! We offer hydraulic cylinders with integrated valve technology for even better safety. The safety measures can reliably ensure braking of the cylinder movement and prevent unintentional lowering, even if a hose line has failed.',
        listItems: ["Valves can be installed in the cylinder base or on the cylinder", "Various load-holding and releasable check valves", "Experienced fluid specialists configure the valve technology in-house"],
    }

    const cylinder6 = {
        image: imgEngineeredby_Imagetext_01,
        listItems: ["Cylinder strokes up to 5,000 mm, piston diameter up to 600 mm", "Various working pressures from 200 to 350 bar", "Standard cylinders with and without mountings", "Single-and double-acting cylinders", "Differential, tandem, synchronising and plunger cylinders", "Mounting elements, cylinder tubes, piston rods and accessories"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Bosch Rexroth",
            image: imgBosch
        },
        {
            title: "HANSA-FLEX",
            image: imgHF
        },
        {
            title: "Aventics",
            image: imgAventics
        },
        {
            title: "Gates",
            image: imgGates
        },
        {
            title: "Voss",
            image: imgVoss
        },
        {
            title: "Stauff",
            image: imgStauff
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Comprehensively tested, high-quality cylinders",
            description: "Manufactured out of high-strength steel or, if required, stainless steel, all HANSA‑FLEX cylinders meet the highest quality standards and undergo thorough testing on modern test benches.",
            listItems: ["Extensive test processes including test report", "Pressure, leakage and function tests in accordance with standards", "Setting of sensors and valve technology", "Supplied ready to install"],
            image: imgEngineeredby_Imagetext_01
        },
        {
            title: "Your hydraulic cylinder in your choice of colours",
            description: "Our customised cylinders not only match your technical requirements; we also paint them so that they blend in perfectly with the rest of your machine or system.",
            listItems: ["Painted in various colours of the customer’s choice", "Corrosivity category up to C5-M", "Available as one-off, small batch or series produced products"],
            image: imgEngineeredby_Imagetext_01
        },
        {
            title: "Surface treatment for maximum corrosion protection",
            description: "The piston rod material used for HANSA‑FLEX hydraulic cylinders has a corrosion resistance of up to 1000 h verified in salt spray (fog) tests performed in accordance with ISO 3768, class 9 ISO 4540, depending on the material grade and surface coating.",
            listItems: ["Steel (Ck45) hard chrome plated", "Steel (20MnV6) hard chrome plated", "Stainless steel (1.4057) hard chrome plated", "Steel (20MnV6) nickel plated and hard chrome plated", "Steel (42CrMo4V) surface-hardened and hard chrome plated"],
            image: imgEngineeredby_Imagetext_01
        },
        {
            title: "Everything from a single source",
            description: "HANSA‑FLEX manufactures systems and cylinders under one roof. This allows us to offer our customers a perfectly matched overall system with an attractive price-performance ratio.",
            listItems: ["Cylinders designed to match the hydraulic unit", "Manufacture of hydraulic systems including all components", "Installation by HANSA‑FLEX Industrial Service if required", "Tests performed during and after bringing into first use"],
            image: imgEngineeredby_Imagetext_01
        },
    ];

    const tabTitles = [
        { title: "Testing", eventKey: "Testing" },
        { title: "Choice of colours", eventKey: "Choice of colours" },
        { title: "Corrosion protection", eventKey: "Corrosion protection" },
        { title: "Services", eventKey: "Services" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgSellcomponents}
                    title={"SALES HYDRAULIC COMPONENTS"}
                    paragraph={"L.I.S.-Hydraulics can significantly increase the efficiency and productivity of the customer's processes by advising them on the choice of the right hydraulic components, which will minimize their production downtime."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Our partners in these processes are:"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"Sustainable Solutions in Hydraulic Technology"}
                    subtitle={"We can also provide the customer with insight into the latest generation of hydraulic components, which are less harmful to the environment and which also provide the necessary energy savings. That is why L.I.S.-Hydraulics only uses components of high quality and high durability from renowned brands. For example, we are a service partner of the Bosh Rexroth Group. It is also the intention that the customer can easily find the right components via this website and, if necessary, together with someone from L.I.S.-Hydraulics, and quickly obtain the right price and delivery time."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrialHydraulics}
                                title="Industrial Hydraulics" desc={""} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMobileHydraulics}
                                title="Mobile Hydraulics" desc={""} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPneumatics}
                                title="Pneumatics" desc={""} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompactHydraulics}
                                title="Compact Hydraulics" desc={""} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSpareparts}
                                title="Spare Parts" desc={""} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgpipeComponents}
                                title="Pipe Components(Custom Made)" desc={""} /></Col>

                        </Row>
                    </Container>
                </div>
            </div>

            <ImageDescription title={"BOSCH REXROTH"}
                paragraphs={["Bosch Rexroth’s extensive experience in industrial hydraulics and know-how in intelligently connected hydraulics are the key to increasing your operational efficiency, productivity and competitiveness.",
                    "Intelligent hydraulics built on technological expertise",
                    "Bosch Rexroth’s leading position as a major supplier of industrial hydraulics is based on its intelligent components, systems and extensive application and engineering know-how. We offer the world’s broadest hydraulics portfolio with standard products, application-related systems and state-of-the-art, customized, customer-specific hydraulic solutions.",
                    "Industrial hydraulics with integrated intelligence",
                    "Using highly advanced microelectronics, Bosch Rexroth’s innovation has led to hydraulic solutions that are smarter, more flexible and more capable. From hydraulic pumps and cylinders to hydraulic motors, valves, hoses and more, our powerful and highly efficient products can be easily integrated into the most modern machine concepts. When you invest in a Bosch Rexroth industrial hydraulics product, you can be sure that you will reap the benefits of a perfect application-specific drive solution.",
                    "From standard hydraulics to Engineer Bosch Rexroth is also your ideal partner for development.",
                ]} />

            <ImageDescription title={"AVENTICS"}
                paragraphs={["Aventics is a reliable and innovative pneumatic solution that is part of Emerson’s group. It is a comprehensive AVENTICS portfolio of cylinders, valves, air preparation and pressure regulators, developed in Germany. With a compact design and low-maintenance technology to drive machinery, this pneumatic can be trusted to deliver reliable and efficient operation, even in the most demanding applications and environments."]} />



            {/* <div>
                <SectionHeader
                    title={"HYDRAULIC CYLINDERS FOR ALL FIELDS OF APPLICATION"}
                />
                <ImageText
                    data={cylinder1} />
                <ImageText
                    data={cylinder2}
                    left={false} />
            </div> */}



            {/* <div>
                <SectionHeader
                    title={"OTHER HYDRAULIC UNIT COMPONENTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div> */}

            {/* <div>
                <SectionHeader
                    title={"INTEGRATED SENSORS AND VALVE TECHNOLOGY"}
                    subtitle={"We equip hydraulic cylinders with integrated valve technology and sensors for additional safety functions, precise positioning, trouble-free driving of different movement axes or for automation of movement sequences."}
                />
                <ImageText
                    data={cylinder3} />
                <ImageText
                    data={cylinder4}
                    left={false} />
                <ImageText
                    data={cylinder5} />
            </div> */}

            {/* <div>
                <SectionHeader
                    title={"HYDRAULIC CYLINDERS AND CYLINDER COMPONENTS FROM HANSA‑FLEX"}
                    subtitle={"Our online shop offers a large selection of single- and double-acting cylinders, various components and accessories. We also manufacture special cylinders to drawings or to meet the customer’s requirements."}
                />
                <ImageText
                    data={cylinder6} />
            </div> */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHydraulicInstallation}
                                learnMoreLink={"/Hydraulicinstallation"}
                                title="Hydraulic Installation"
                                desc={"Delivering Customized Hydraulic Solutions with Precision Engineering, Comprehensive Testing, and Ongoing Support to Ensure Maximum Efficiency and Safety for Your Unique Applications."} /></Col>

                            {/* <Col sm={12} md={6} lg={4} ><ImageCard image={imgSellComponent}
                                learnMoreLink={"/Sellcomponents"}
                                title="Sell Components"
                                desc={"HANSA‑FLEX always has the perfect sealing solution. More than 8,000 standard seals available from stock and an in-house manufacturing centre for special seals."} /></Col> */}

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCylinderRepair}
                                learnMoreLink={"/hydraulic-cyclinder-repair"}
                                title="Hydraulic Cylinder"
                                desc={"Hydraulic cylinders are subject to extreme stresses due to their continual extending and retracting. The result is wear, which puts the whole working process at risk of failure. Overhauling a cylinder is a good alternative to buying a new one. The L.I.S.-Hydraulics cylinder repair centre repairs hydraulic cylinders of all sizes and manufacturer, hydraulic pumps, valves, power units and motors rapidly and with minimum downtime."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgStudyDesignMaintainance}
                                learnMoreLink={"/StudyDesignandMaintenace"}
                                title="Study, Design and Maintainance"
                                desc={"L.I.S.-Hydraulics assists customers in developing new hydraulic projects or converting existing installations. By translating the application into technical specifications, L.I.S.-Hydraulics helps to make the right choice of the necessary components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCounterSales}
                                learnMoreLink={"/Countersales"}
                                title="Counter Sales"
                                desc={"L.I.S.-Hydraulics is always available at the counter with advice and action."} /></Col>

                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}
export default Sellcomponents