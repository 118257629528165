import React, { useEffect } from 'react';
import '../PageCss/HansaFlexIndiaPages/Journey.css';
import JourneyCard from '../../Components/JourneyCard/JourneyCard';
import imgWarehouse from '../../assets/Warehouse.png'
import JourneyCardR from '../../Components/JourneyCard/JourneyCardR';
import CenterStage from '../../Components/CenterStage/CenterStage';
import AOS from 'aos';
import 'aos/dist/aos.css';

import imgBelgium_Journey from '../../assets/Journey/New folder/LIS Hydraulics.png'
import imgStartup from '../../assets/Journey/New folder/startup.png'
import imgGrow from '../../assets/Journey/New folder/grow.png'
import imgHall from '../../assets/Journey/New folder/hall.png'
import imgRelationship from '../../assets/Journey/New folder/handshake.png'
import imgInvestment from '../../assets/Journey/New folder/investment.png'
import imgLogo from '../../assets/Journey/New folder/LIS logo sqaure.png'

function Journey() {

    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 300,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);



    return (
        <div className='journey-content'>
            <CenterStage imageSrc={imgBelgium_Journey} title="Our Journey with HANSA-FLEX: A Legacy of Hydraulic Expertise" paragraph="Since our founding in 1989, L.I.S.-Hydraulics has evolved from a local venture into a trusted name in hydraulic solutions. From our early days in Genk to becoming a service partner of Bosch Rexroth and joining the renowned HANSA-FLEX Group, our journey reflects a commitment to quality, innovation, and growth. Discover how we’ve built on our legacy to deliver cutting-edge hydraulic services with the backing of an international leader." />

            <div className='div-timeline-wrapper'>
                <br /><br /><br /><br />
                {isMobile ? <></> : <><div className='vertical-line'></div></>}

                <div>
                    <JourneyCard
                        title="1989"
                        description="L.I.S.-Hydraulics was established by founders Toon Claes and Jos Darcis, both coming from the general industrial supply sector. They took their first steps into the hydraulics industry, recognizing the potential for specialized hydraulic solutions. They founded L.I.S.-Hydraulics to focus exclusively on this field."
                        imageUrl={imgStartup}
                        additionalDescription="Founding and Early Vision"
                    />
                </div>

                <div >
                    <JourneyCardR
                        title="1997"
                        description="After initially operating from a business center in Genk, the company built its own facility at the Genk Zuid industrial estate to support its expanding operations and client base."
                        imageUrl={imgGrow}
                        additionalDescription="Growth and Expansion"
                    />
                </div>

                <div >
                    <JourneyCard
                        title="2000"
                        description="Responding to growing demand, L.I.S.-Hydraulics expanded their premises with a second hall, increasing their capacity to manage more projects and further develop their services."
                        imageUrl={imgHall}
                        additionalDescription="Further Expansion"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2008"
                        description="L.I.S.-Hydraulics became an official service partner of Bosch Rexroth, aligning with a globally respected name in hydraulics to deliver high-quality, reliable solutions."
                        imageUrl={imgRelationship}
                        additionalDescription="Becoming a Bosch Rexroth Service Partner"
                    />
                </div>

                <div>
                    <JourneyCard
                        title="2009"
                        description="To support specialized hydraulic manufacturing and repairs, L.I.S.-Hydraulics added a third hall. This expansion included advanced machinery, such as teach-in lathes, CNC lathes, milling machines, welding equipment, and dedicated equipment for cylinder repairs."
                        imageUrl={imgInvestment}
                        additionalDescription="Investment in Technology"
                    />
                </div>


                <div>
                    <JourneyCardR
                        title="2021"
                        description="L.I.S.-Hydraulics became part of the HANSA-FLEX Group, enhancing its capacity to serve as a trusted hydraulic partner in Belgium. This partnership provided access to the resources, network, and expertise of HANSA-FLEX, further strengthening L.I.S.-Hydraulics' position in the market."
                        imageUrl={imgLogo}
                        additionalDescription="Joining the HANSA-FLEX Group"
                    />
                </div>
            </div>
        </div>
    );
}

export default Journey;
