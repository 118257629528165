import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMetalHoseForExtreme from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/metalHosesForExtreme.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHighQualityMaterials from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/highQualityMaterials.jpg'
import imgTheMostSuitable from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/theMostSuitable.jpg'
import imgExtensiveAdvice from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/extensiveadvice.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgPTFEHoses from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/ptfeHoses.jpg'
import imgExpansion from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/expansionJoints.jpg'
import imgOneOff from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/oneOffSpecial.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgTopClassMetalHose from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/topClassMetalHose.jpg'




function MetalHoses() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const HighQuality1 = {
        image: imgHighQualityMaterials,
        title: 'High-quality materials and professional craftsmanship',
        description: 'First-class corrugated stainless steel hoses form the basis of the quality of L.I.S.-Hydraulics metal hoses. However, only certified assembly processes by qualified personnel turn them into truly robust pipelines. Using the most modern methods, we not only test individual pieces but also test up to 100% of serially produced components for leaks.',
        listItems: ["Highest quality and precision standards", "Certified assembly processes", "100% leakage tests"]
    }

    const HighQuality2 = {
        image: imgTheMostSuitable,
        title: 'The most suitable fitting for every situation',
        description: 'Metal hoses are widely used, particularly due to their wide temperature range, high vacuum resistance, and availability in many different sizes. In addition to acids and alkalis, they can also transport hot liquids or gases.',
        listItems: ["Wide temperature range from -196°C to +550°C", "Suitable for pressures up to 300 bar and vacuum", "Nominal diameters from DN 6 to DN 250 mm"]
    }

    const HighQuality3 = {
        image: imgExtensiveAdvice,
        title: 'Comprehensive advice from L.I.S.-Hydraulics',
        description: 'L.I.S.-Hydraulics offers a wide range of products with a variety of different hose types. Experienced consultants at L.I.S.-Hydraulics use their fluid technology expertise to find the ideal product for you. Additionally, they provide flexible and responsive support throughout the entire system design phase.',
        listItems: ["Wide range of services for hose inspection, testing, and failure analysis", "Reliable implementation of customer-specific oil cleanliness requirements", "Excellent material availability and customized solutions for the customer"]
    }

    const HighQuality4 = {
        image: imgTopClassMetalHose,
        listItems: ["Parallel corrugation construction for high performance", "Different constructions (single wall and double wall)", "Longitudinally welded", "Various materials for hoses, e.g., stainless steel 1.4404", "Braid reinforcement, e.g., stainless steel 1.4301", "Components designed to work perfectly together"]
    }

    const hfMetalHoses = [
        {
            title: "Numerous variants",
            description: "Metal hoses are available as double-walled hoses or as strip-wound hoses",
            image: imgMark
        },
        {
            title: "Comprehensive quality testing",
            description: "Each hose line undergoes rigorous testing, with pressure test certificates available on request",
            image: imgMark
        },
        {
            title: "Maximum cleanliness",
            description: "We supply metal hoses that meet customer requirements for oil, grease, and LABS absence",
            image: imgMark
        },
        {
            title: "Fast delivery",
            description: "We supply all L.I.S.-Hydraulics hoses reliably and promptly.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMetalHoseForExtreme}
                    title={"METAL HOSES FOR EXTREME CONDITIONS"}
                    paragraph={"The materials used in metal hoses make them extremely resistant to corrosion and abrasion. Excellent temperature tolerance, vacuum resistance, and extremely low permeability make metal hoses the preferred choice for transporting solid, liquid, and gaseous substances under particularly demanding conditions. L.I.S.-Hydraulics offers comprehensive advice for selecting the best metal hose solution."}
                />

            </div>

            <div>
                <SectionHeader
                    title={"HIGHEST QUALITY AND RELIABILITY WITH L.I.S.-Hydraulics"}
                />
            </div>

            <div>
                <ImageText
                    data={HighQuality1} />

                <ImageText
                    data={HighQuality2}
                    left={false} />

                <ImageText
                    data={HighQuality3} />
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"L.I.S.-Hydraulics TOP-CLASS METAL HOSES WITH EXCEPTIONAL PERFORMANCE FEATURES"}
                        subtitle={"L.I.S.-Hydraulics metal hoses are manufactured from high-quality materials using state-of-the-art processing methods. This ensures that our metal hoses meet the requirements of all relevant standards and approvals, such as DIN EN ISO 15085-2 CL 1, 2014/68/EU (PED), and DNV."}
                    />
                    <FeatureCardCarousel
                        featuresData={hfMetalHoses} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"FIRST-CLASS METAL HOSES FROM FLUID SPECIALISTS"}
                    subtitle={"High-temperature tolerance and resistance to aggressive media are the basis of the excellent performance characteristics of L.I.S.-Hydraulics metal hoses."}
                />
                <ImageText data={HighQuality4} />
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Video */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPTFEHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"L.I.S.-Hydraulics PTFE hoses are distinguished by their excellent chemical resistance to most media, high flexibility, and long service life. In the food and pharmaceutical industries, taste and odor neutrality, as well as bacteriological safety, make them ideal for transporting potentially problematic products. In shipbuilding or the aerospace industry, PTFE hoses safely transport fuel or cooling water."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgExpansion} learnMoreLink={"/Expansionjoints"}
                                title="Expansion joints for hoses and pipes."
                                desc={"Expansion joints reliably accommodate changes in length and angle caused by temperature differences, foundation settling, or inaccurate installation. They protect flexible pipes, machinery, and equipment from damage. Expansion joints also play a key role in dampening vibrations from engines and turbines, preventing their transmission along exhaust pipes or compressed air lines."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgOneOff} learnMoreLink={"/Customisedassembly"}
                                title="Special one-off solutions"
                                desc={"We pre-assemble custom hydraulic hose lines from samples and according to specific customer requirements for low, medium, and high-pressure applications with associated fittings in our branches. Our hose specialists always keep people and environmental safety in mind when selecting materials and hose-to-fitting joining method. Whatever your need, we can advise and manufacture a suitable hose line."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}
export default MetalHoses;
