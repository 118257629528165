import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/Stage.jpg'
import ReliablyConnect from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/ReliablyConnect.jpg'
import BuiltForExtreme from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/BuiltForExtreme.jpg'
import TechnicalAdvice from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/TechnicalAdvice.jpg'
import BSPAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/BSPAdapter.jpg'
import JICAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/JICAdapter.jpg'
import ORFSAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/ORFSAdapter.jpg'
import MetricAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/MetricAdapter.jpg'
import WithNationalPipe from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/WithNationalPipe.jpg'
import Standardised from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/Standardised.jpg'
import AdapterWithJapanese from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/AdapterWithJapanese.jpg'
import HydraulicAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/HydraulicAdapter.jpg'
import StaLeakProofge from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/LeakProof.jpg'
import MadeToMeasure from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/MadeToMeasure.jpg'
import HydraulicConnection from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/HydraulicConnection.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs'

const Adapters = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Reliable functionality",
            description: "Designed with a 4-fold safety factor for the design working pressure",
            image: imgMark
        },
        {
            title: "Dependable product quality",
            description: "Manufactured in the proven L.I.S.-Hydraulics quality including extensive tests",
            image: imgMark
        },
        {
            title: "Durable materials",
            description: "Ozone-resistant O-rings and high corrosion resistance against red rust",
            image: imgMark
        },
        {
            title: "Easy assembly",
            description: "The adapters are designed in such a way that they can be quickly and safely installed.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const Data1 = {
        title: 'Reliably connect different thread types',
        description: 'Adapters are required when hydraulic components with different connection types need to be reliably joined in mobile or industrial hydraulics. Adapters create a reliable, sealed transition between different connector types and enable the cost-effective adaptation of hydraulic components of different designs or from different manufacturers. L.I.S.-Hydraulics supplies a wide range of adapters. These allow adaptation of any commercially available connector, including inch and metric thread types. In addition to connection types such as JIC (37° ISO 8434-2), ORFS (flat seal ISO 8434-3), BSP (60° ISO 8434-6/BS 5200) and NPT/F (SAE J514), L.I.S.-Hydraulics also offers special types including 45° SAE connections.',
        image: ReliablyConnect
    }

    const Data2 = {
        title: 'Built for extreme use environments and high loadings',
        description: 'Hydraulic connectors such as adapters find use in challenging applications and are exposed to extreme loads including, for example, oscillations and vibrations. Therefore all L.I.S.-Hydraulics products are manufactured to the highest quality standards. As part of our quality assurance system, we carry out extensive testing and thus ensure that adapters can fulfil their role reliably. All adapters are available in steel with a zinc-nickel surface coating as standard. This guarantees high corrosion resistance to red rust of up to 1,500 h. On request, these adapters are also available in other materials such as stainless steel for maximum service life, even under the harshest conditions of use.',
        image: BuiltForExtreme
    }

    const Data3 = {
        title: 'Technical advice from hydraulics experts',
        description: 'L.I.S.-Hydraulics offers you not only a wide range of high-quality hydraulic adapters but also extensive technical advice [Link to contact details] about adapting your hydraulic systems. We take care to ensure that adapters are not only mechanically suitable but also reliably fulfil their intended function. With this in mind, our experienced fluid technology experts check through your engineering drawings and produce the precise technical solution that most efficiently covers your requirements. Whether you want to connect European and Asian systems, use a combination of different adapters or need to develop a special solution, no technical challenge is too great for us.',
        image: TechnicalAdvice
    }

    const Data4 = {
        title: 'Adapters with National Pipe Taper (NPT) thread',
        description: 'The seal of the adapters with the NPT thread commonly seen on the US-American market is achieved by wrapping the external threads with Teflon tape before installation to provide the maximum effective seal between the flanks. The NPT/F adapters have a 60° thread angle.',
        listItems: ["Straight construction, elbow (45° or 90°), T, L or cross-shaped", "Push-on, threaded, bulkhead, screw-in or screw-on connectors", "Available with tapered external and external threads"],
        image: WithNationalPipe
    }

    const Data5 = {
        title: 'Standardised Society of Automotive Engineers (SAE) threads',
        description: 'SAE adapters have cylindrical cone connections with a 45° sealing surface and an O-ring at the sealing collar. SAE adapters with an external thread on the other hand make a metallic seal.',
        listItems: ["Construction forms: straight, 90° elbow", "Design types: screw-in, screw-on and threaded connectors", "Materials: steel and brass"],
        image: Standardised
    }

    const Data6 = {
        title: 'Adapters with Japanese Industrial Standard (JIS) threads',
        description: 'The JIS thread, which was originally used mainly in Asia, has sealing flank surfaces inclined at 30° (thread angle 60°) and is designed similar to the 37° JIC connection (UNF thread).',
        listItems: ["Straight construction, elbows (45° or 90°)", "Design types: Screw-in connector, screw-on connector", "Available in metric and inch thread types"],
        image: AdapterWithJapanese
    }

    const Data7 = {
        listItems: [
            "Available in various materials such as stainless steel and brass",
            "Straight construction, elbow (45° or 90°), T, L or cross-shaped",
            "Various thread types, e.g. BSP, BSPT, NPT/F, UNF, JIS, SAE and JIC",
            "Metallic seal, soft seal and O-ring",
            "Connection types include BSP 60°, JIC 37°, ORFS and NPT/"
        ],
        image: HydraulicAdapter
    }

    //HfTab data
    const imageTextData = [
        {
            title: 'BSP and BSPT in accordance with ISO 8434-6 (BS 520)',
            description: "Adapters in accordance with ISO 8434-6 (BS 5200) with BSP threads have an imperial thread that is compatible with the Whitworth Pipe thread in accordance with DIN 228. In addition to versions with parallel BSP threads (British Standard Pipe thread), we also supply adapters with the tapered BSPT external thread. The connection is made with a 60° sealing cone.",
            listItems: ["Straight construction, elbow (45° or 90°), T, L or cross-shaped", "Adjustable in direction or installed as bent pipe", "Push-on, threaded, bulkhead, screw-in or screw-on connectors"],
            buttonName: "ALL PRODUCTS",
            image: BSPAdapter
        },
        {
            title: 'JIC adapters in accordance with ISO 8434-2',
            description: "Adapters in accordance with ISO 8434-2 with a 37° (74°) sealing cone have a UNF thread (Unified National Fine thread) in accordance with ISO 263 (37° flared connection). The connection is made by the 37° (74°) sealing cone.",
            listItems: [
                "Straight construction, elbow (45° or 90°), T, L or cross-shaped",
                "Push-on, threaded, bulkhead, screw-in or screw-on connectors",
                "Adjustable in direction"
            ],
            buttonName: "ALL JIC ADAPTERS",
            image: JICAdapter
        },
        {
            title: 'ORFS adapters in accordance with ISO 8434-3',
            description: "Adapters in accordance with ISO 8434-3 have a flat elastomeric seal and a UNF thread (Unified National Fine thread) in accordance with ISO 263.",
            listItems: [
                "Straight construction, elbow (45° or 90°), T, L or cross-shaped",
                "Push-on, threaded, bulkhead, screw-in or screw-on connectors",
                "Adjustable in direction"
            ],
            buttonName: "PRODUCTS",
            image: ORFSAdapter
        },
        {
            title: 'Adapters for metric threads',
            description: "With metric adapters from L.I.S.-Hydraulics, you can securely connect hydraulic components with purely metric threads or metric type threads to components with BSP external threads.",
            listItems: [
                "Construction forms: straight, 90° elbow",
                "Design types: screw-in connections, push-on connections and connectors",
            ],
            buttonName: "ONLINE SHOP",
            image: MetricAdapter
        }
    ];

    const tabTitles = [
        { title: "BSP adapters", eventKey: "BSP adapters" },
        { title: "JIC adapters", eventKey: "JIC adapters" },
        { title: "ORFS adapters", eventKey: "ORFS adapters" },
        { title: "Metric adapters", eventKey: "Metric adapters" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"THE RIGHT CONNECTION WITH HYDRAULIC ADAPTERS FROM L.I.S.-Hydraulics"}
                    paragraph={"Adapters create a safe and leak-free connection between components with differing thread types. L.I.S.-Hydraulics offers an extensive portfolio of hydraulic adapters, such as threaded or transition adapters in all common connection forms and meeting all standards, as well as special forms including 45° SAE connections. With a zinc-nickel surface coating, the adapters also have an extremely high corrosion resistance against red rust."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULIC COMPONENTS RELIABLY AND SAFELY CONNECTED"}
                    subtitle={"There are no limits to the possibilities open to you for all types of connections with adapters from L.I.S.-Hydraulics."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"OPTIMUM FLOW AND LEAK-PROOF POSITIVE-FIT CONNECTIONS"}
                    subtitle={"L.I.S.-Hydraulics adapters meet the highest quality demands in all areas of mobile and industrial hydraulics and ensure safe, reliable connections."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THE L.I.S.-Hydraulics ADAPTER RANGE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"OTHER ADAPTERS AVAILABLE FROM L.I.S.-Hydraulics"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULIC ADAPTERS FOR EVERY TECHNICAL REQUIREMENT"}
                    subtitle={"L.I.S.-Hydraulics’s product portfolio includes a wide range of adapters for all commonly available types of connection and standards as well as special designs."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"CHANGE HOSES IN SECONDS WITH THE SSKV COUPLING"}
                    subtitle={"The push-in clamping shell connection developed by L.I.S.-Hydraulics combines the advantages of a coupling and a fitting. A clamping shell secures the connection between plug and sleeve. In addition the SSKV coupling does not add to the size of the connection and can be attached without the need for tools, an unimaginable benefit when installation space is tight."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={StaLeakProofge}
                                title="Leak-proof positive connection with standardised pipe fittings"
                                desc={"L.I.S.-Hydraulics pipe fittings rely on a tight positive fit with one another to create a safe connection, even when subjected to high working pressures, pressure peaks and..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure}
                                title="Made-to-measure bent pipes"
                                desc={"Components are produced in the L.I.S.-Hydraulics pipe bending centre to the highest computer-controlled standards of precision for the OEM and replacement part..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicConnection}
                                title="Hydraulic connection technology at L.I.S.-Hydraulics"
                                desc={"L.I.S.-Hydraulics offers a wide range of hydraulic connection technology products such as fittings and threaded connections, for the most part available directly from..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Adapters