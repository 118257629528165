import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';

import imgBelgium_Dragon from "../../assets/news-events/Belgium_Dragon.jpg"
import imgBelgium_LIS from "../../assets/news-events/Belgium_LIS.jpg"
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';


const ElectraMining = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgBelgium_Dragon}
                    title="Building dreams with HANSA-FLEX hydraulics "
                    paragraph="With its imaginative ideas and innovative technology, the Belgian company Fisheye creates unforgettable event and exhibition experiences. Hydraulic components from HANSA-FLEX & L.I.S.-Hydraulics bring its extraordinary creations to life. " />

            </div>

            <div className='div-gray center-all-items'>
                <SectionHeader
                    title="HANSA-FLEX Belgium working with Fisheye since 2023"
                    subtitle="Two spectacular projects have been completed together. In the process, both partners have got to know and appreciate each other."
                />

            </div>

            <br /><br />
            <br /><br />

            <ImageDescription
                imageSrc={imgBelgium_LIS}
                paragraphs={[
                    "If you can dream it, we can build it' is the slogan under which Fisheye from Wetteren in Belgium offers one-stop-shop solutions for extraordinary exhibits. The multifaceted production company combines creativity, craftsmanship and technology, thinks out of the box and enables its customers to create unforgettable experiences. Customers include the Tomorrowland Festival and the media company Studio 100, among others. In Fisheye projects, virtual and physical space often mix. Cutting-edge software meets rustic wooden design, while filigree lighting installations set the scene for exhibits weighing several tonnes. To ensure that the transition between physical spaces and media is as seamless as possible, the hydraulics must also integrate harmoniously into the overall concept. For two of their projects to date, the creative experts have enlisted the support of HANSA‑FLEX & L.I.S.-Hydraulics to provide solutions for highly complex hydraulic requirements."
                ]}
                alignment={'center'}
            />

            <ImageDescription
                paragraphs={[
                    "Fisheye’s creativity experts and HANSA‑FLEX’s hydraulics specialists tested their collaboration through a pilot project for the musical Red Star Line, which debuted in March 2023. The project featured a movable replica of the Belgenland ship. HANSA‑FLEX & L.I.S.-Hydraulics designed a system that allowed the ship's bow to move smoothly and authentically, replicating ocean waves and storms. Despite challenges in balancing material requirements, weight, and hydraulic performance, the team met the project's demands on time and within budget. Fisheye CEO Stijn Slabbinck praised the partnership, noting a strong alignment in commitment from both sides. "
                ]}
                alignment={'center'}
            />

            <ImageDescription
                paragraphs={[
                    "A standout project was the overhaul of the Rose Garden stage installation by Tomorrowland and Fisheye, featuring Rosie, a dragon lady with intricate movements. Controlling Rosie’s delicate eye, mouth, and head motions required precision hydraulics, achieved using special proportioning valves and custom valve control software developed by HANSA‑FLEX and Fisheye. Stijn Slabbinck of Fisheye praised the excellent cooperation, while Jan Delvaux from HANSA‑FLEX commended the teamwork with L.I.S.-Hydraulics and looks forward to future projects."
                ]}
                alignment={'center'}
            />

            {/* <div className='div-white center-all-items'>
                <SectionHeader
                    title="Hydraulic finesse for the dragon lady"
                    subtitle="A standout project was the overhaul of the Rose Garden stage installation by Tomorrowland and Fisheye, featuring Rosie, a dragon lady with intricate movements. Controlling Rosie’s delicate eye, mouth, and head motions required precision hydraulics, achieved using special proportioning valves and custom valve control software developed by HANSA‑FLEX and Fisheye. Stijn Slabbinck of Fisheye praised the excellent cooperation, while Jan Delvaux from HANSA‑FLEX commended the teamwork with L.I.S.-Hydraulics and looks forward to future projects."
                />
            </div> */}


            <br /><br />
        </div>
    );
}

export default ElectraMining