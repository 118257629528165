import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivacyPolicyPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <SectionHeader title="Privacy Policy" />
            <TextCaption
                paragraph='We are aware that you place your trust in us. We see it as our responsibility to protect your privacy. On this page, we will tell you what data we collect when you use our website, why we collect this data and how we use it to improve your user experience. This way you understand exactly how we work.'
            />
            <TextCaption
                paragraph={"This privacy policy applies to the services of L.I.S.-Hydraulics. You should be aware that L.I.S.-Hydraulics is not responsible for the privacy policies of other sites and sources. By using this website, you indicate that you accept the privacy policy."}
            />
            <TextCaption
                paragraph={"L.I.S.-Hydraulics respects the privacy of all users of its site and ensures that the personal information you provide to us is treated confidentially."}
            />

            <TextCaption subtitle={"Our use of collected data, use of our services"}
                paragraph={"When you sign up for one of our services, we ask you to provide personal information. This information is used to perform the service. The information is stored on L.I.S.-Hydraulics's own secure servers or those of a third party. We will not combine this information with other personal information that we have."}
            />

            <TextCaption subtitle={"Communication"}
                paragraph={"When you send us an e-mail or other messages, we may retain those messages. Sometimes we ask you for your personal data that is relevant to the situation in question. This makes it possible to process your questions and answer your requests. The data is stored on L.I.S.-Hydraulics' own secure servers or those of a third party. We will not combine this data with other personal data that we have"}
            />

            <TextCaption subtitle={"Purposes"}
                paragraph={"We do not collect or use information for purposes other than the purposes described in this Privacy Policy unless we have obtained your prior consent. L.I.S.-Hydraulics may collect and process the following personal data:"}
            />

            <div style={{ margin: '20px 0px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Name</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- First name</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Email Address</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Address</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Telephone number (landline and/or mobile phone)</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Fax</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- VAT number</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Language</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Communication preference</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Function</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Social media data</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Billing address</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Company</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Title (gender)</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- All personal data contained in the free field of the contact form</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Exchanging business cards</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Company visit</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Making an appointment</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Registration for entrance tickets to trade fairs</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Preparation of a quotation, orders, order confirmations, delivery notes and invoices</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>- Providing support/assistance</p>
            </div>

            <TextCaption subtitle={"Third parties"}
                paragraph={"The information is not shared with third parties. In some cases, the information may be shared internally. Our employees are required to respect the confidentiality of your data."}
            />

            <TextCaption subtitle={"Changes"}
                paragraph={"This privacy statement is tailored to the use of and the possibilities on this site. Any adjustments and/or changes to this site may lead to changes in this privacy statement. It is therefore advisable to consult this privacy statement regularly."}
            />

            <TextCaption subtitle={"Choices for personal data"}
                paragraph={"We offer all visitors the opportunity to view, change, or delete all personal information that has been provided to us at that time."}
            />

            <TextCaption subtitle={"Adjust/unsubscribe from communications"}
                paragraph={"If you want to adjust your data or have yourself removed from our files, you can contact us. See contact details below."}
            />

            <TextCaption subtitle={"Questions and feedback"}
                paragraph={"We regularly check whether we comply with this privacy policy. If you have any questions about this privacy policy, you can contact us."}
            />

        </div>
    );
}

export default PrivacyPolicyPage;