import React from 'react'
import imgBelgiumfooter_runninghorses from '../../assets/Belgiumfooter_runninghorses.jpg'
import './Horizontal.css'
import { Col, Row } from 'react-bootstrap'

const Horizontal = () => {
    return (
        <div className="horizontalImageBody">
                {/* <img src={imgBelgiumfooter_runninghorses} alt="DubaiImage" className="responsive-image" /> */}
        </div>
    )
}

export default Horizontal