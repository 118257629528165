import React, { useEffect } from 'react';
import CenterStage from '../Components/CenterStage/CenterStage';
import imgCareerStage from '../assets/Careers/career_stage_1.jpg';
import Promoter from '../Components/Promoter/Promoter';
import { Col, Row } from 'react-bootstrap';
import { MdEmail, MdLocationOn } from 'react-icons/md';
// import imgWork from '../assets/Careers/work.png';
import imgWork from '../assets/Careers/imgbin_sticker-architectural-engineering-computer-icons-png.png';
import imgJoinUs from '../assets/Careers/JoinUs2.png';
import TextCaption from '../Components/TextCaption/TextCaption';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import ImageText from '../Components/ImageText/ImageText';
import imgjob_01 from "../assets/Job_01.jpg"
import imgjob_02 from "../assets/Job_02.jpg"
import imgjob_03 from "../assets/Job_03.jpg"
import imgjob_04 from "../assets/Job_04.jpg"
import TwoColumnPoints from '../Components/TwoColumnPoints/TwoColumnPoints';

// import ImageDescription from '../Components/ImageDescription/ImageDescription';

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  //hall of fame

  const isMobile = window.innerWidth <= 768;
  const job_01 = {
    image: imgjob_01,
    title: 'We are hiring: Drongen',
    description: 'Warehouse Operator Toonbank',
    listItems: ["Do you have knowledge of hydraulics?", "Do you get energy from helping people and social contact?", "Do you like to roll up your sleeves?", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  }

  const job_02 = {
    image: imgjob_04,
    title: 'Open vacancies at HANSA-FLEX',
    description: 'Click here for open jobs at HANSA-FLEX.',
    buttonName: 'Explore more jobs',
    buttonLink: '/'
  }

  const job_03 = {
    image: imgjob_03,
    title: 'We are recruiting for: TECHNICAL COMMERCIAL OFFICER in Hasselt/Genk',
    buttonName: 'Job Description',
    buttonLink: 'Job_description_pdf/Vacature_HANSA-FLEX_-_TCO__Hasselt-Genk_.pdf',
    listItems: ["Are you customer orientated and administratively strong?", "Do you have strong technical skills?", "Do you have a knack for helping customers at the counter? ", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  }


  const job_04 = {
    image: imgjob_02,
    title: 'We are recruiting for: SERVICE TECHNIQUE in GENK',
    buttonName: 'Job Description',
    buttonLink: 'Job_description_pdf/Service_technieker_Genk.pdf',
    listItems: ["Do you have knowledge of hydraulics?", "Do you get energy from helping people and social contact?", "Do you like to roll up your sleeves? ", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  }

  return (
    <div>
      <CenterStage title={"Welcome to the career portal of L.I.S.-Hydraulics!"}
        imageSrc={imgCareerStage}
        paragraph={"At L.I.S.-Hydraulics, part of the HANSA-FLEX Group , we know that employees can only perform optimally if they feel good."}
      />

      <TextCaption
        title={"Why work at L.I.S.-Hydraulics?"}
        paragraph={[
          "If you are looking for a work environment where innovation, teamwork and personal growth are key, then scroll no further. At L.I.S.-Hydraulics you will have the opportunity to work for an international player in the hydraulics sector, where no day is the same and your dedication makes the difference!As an employer, we support our employees in various ways:"]}
        bulletPoints={["Open feedback culture and collegial cooperation.",
          "As a family-owned company, we encourage open communication and teamwork.",
          "Through that open communication, we create a flexible work-life balance."]}
      />
      <br />
      <TextCaption
        paragraph={"Whether you are an experienced professional or just starting your career, we welcome people with a passion for technology and a driven attitude."}
      />

      <br /><br />
      {/* <div>
        <br />
        <ImageText data={job_01} left={false} /> <br /><br />
      </div> */}


      <div >
        <br />
        <ImageText data={job_03}  /> <br /><br />

        <div >
          <br />
          <ImageText data={job_04} left={false}/> <br /><br />
        </div>
      </div>

      <div >
        <br />
        <ImageText data={job_02} /> <br /><br />
      </div>


      <TextCaption
        title={"Not your position?"}
        paragraph={<>We still look forward to meeting you and getting to know you and your skills. Simply send us an application on <a href="mailto:info@lishydraulics.be" onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:info@lishydraulics.be'; }}>info@lishydraulics.be</a> and introduce yourself to us.</>}
      />

      {/* <div className=' div-white'>
        <SectionHeader title={"Why join us ?"} paragraph={"A Career Full of Advantages:We at HANSA-FLEX know that employees can only achieve their best performance if they feel comfortable. As an employer, we therefore support our employees in a variety of ways:"} />
      </div>

      <div>
        <TwoColumnPoints title={"Open feedback culture and collegial cohesion"}
          points={[
            "As a family business with flat hierarchies, we promote open exchange and new forms of cooperation.",
            "Through numerous company events and joint activities, we promote togetherness.Flexible life planning and health promotion",
            "With our long-term work account, you can plan flexibly in every phase of your life - a sabbatical, increasing your financial leeway during parental leave or your earlier retirement.",
            "The Hansefit and Qualitrain fitness programs offers all employees the opportunity to take part in sports after work. ",


          ]} />


      </div> */}

      {isMobile ?
        <>

          <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
            <Row>
              <Col xs={12} md={6}>
                <h2>Apply Now !</h2>
                <p>
                  <MdLocationOn /> HANSA-FLEX Hydraulics NV,<br />
                  Industriepark-Drongen 12,<br />
                  9031 Gent, Belgium
                </p>
                <p>
                  <MdEmail /> Email us at <a href="mailto:info@lishydraulics.be" style={{ color: 'white' }}>info@lishydraulics.be</a> now!
                </p>
              </Col>
            </Row>
          </div>
        </> : <Promoter
          title={"Contact us now !"}
          checklistItems={[
            "Whether you are an experienced professional or at the beginning of your career, we welcome people with a passion for technology and a driven attitude.",
            <>Are you convinced? Great, we'd love to hear from you! Email us at <a href="mailto:info@lishydraulics.be" onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:info@lishydraulics.be'; }}>info@lishydraulics.be</a>.</>,
            "Come work at L.I.S.-Hydraulics and be part of our success story!"
          ]}
          imageUrl={imgJoinUs}
        />}

    </div >
  );
}

export default Careers;