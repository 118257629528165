import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgScanner_Centerspread from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgScanner_Imagetext_01 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_01.jpg'
import imgScanner_Imagetext_02 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_02.jpg'
import imgScanner_Imagetext_03 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgScanner_Imagecard_01 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_01.jpg'
import imgScanner_Imagecard_02 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_02.jpg'
import imgScanner_Imagecard_03 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_03.jpg'


const Scannersolutions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Scanner_Imagetext_01 = {
        image: imgScanner_Imagetext_01,
        title: 'Capture the product requirement with the scanner and the L.I.S.-Hydraulics Easy Order app',
        listItems: ['L.I.S.-Hydraulics offers the Scan-to-Order app in combination with robust, high-quality Zebra scanners. These are about the size of a mobile telephone and are therefore extremely portable. The customer receives a preconfigured scanner with the Scan-to-Order app already installed. You do not have to concern yourself with other technical details. The scanners enable you to capture your product needs by scanning in the scannable code on the container or at the position of the desired product on the shelf. The app transmits your product needs directly to L.I.S.-Hydraulics.'],
    }

    const Scanner_Imagetext_02 = {
        image: imgScanner_Imagetext_02,
        title: 'Optimum control of kanban delivery with scanners and the kanban app',
        listItems: ['With kanban, L.I.S.-Hydraulics ensures goods availability in real-time directly to the location on your production floor where they are needed. This relies on the multi-container method. Each container or “bin” is marked with a scannable code indicating, among other things, the ID number and the physical number of articles in the container when full. When the first container becomes empty, the installers capture the code on the empty container with the hand scanner. The kanban app reports the corresponding need immediately to the L.I.S.-Hydraulics system. The kanban shelf or container is then filled in sync with the actual consumption.'],
    }


    const Scanner_Imagetext_03 = {
        image: imgScanner_Imagetext_03,
        title: 'Determine the status of hose lines with the X-CODE Manager app',
        listItems: ['The X-CODE Manager app can be installed very simply on your mobile device. This synchronises automatically with the data on your web-based application of X-CODE Manager. You capture the individual X-CODE on one of your hose lines using the camera on your mobile device. After you have scanned the X-CODE, the app shows you the details of the specific hose line, such as installation date and the upcoming inspection dates. If necessary, you can order an inspection by L.I.S.-Hydraulics or a replacement of the relevant hose line directly from the app. In order to be able to use the X-CODE Manager app, you need either the “Flexible” or “Expert” licence package.'],
    }

    const featuresData = [
        {
            title: "Mobile and quick",
            description: "You can take our scanners with you wherever you go and capture data in seconds.",
            image: imgMark
        },
        {
            title: "Everything set out clearly",
            description: "Our apps give you a full overview of your requirements at any time.",
            image: imgMark
        },
        {
            title: "Always correctly captured",
            description: "Scanners help you avoid data input and transfer errors.",
            image: imgMark
        },
        {
            title: "Your usual material descriptions",
            description: "Display your warehouse goods containers with your own familiar material descriptions.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];











    return (
        <div><CenterStage
            imageSrc={imgScanner_Centerspread}
            title={"DETERMINE YOUR REQUIREMENTS SIMPLY AND DIGITALLY BY SCANNER"}
            paragraph={"Yes, we scan! Scanner solutions from L.I.S.-Hydraulics enable you to determine your goods requirement for fluid technology in the warehouse or on the production line more quickly and order conveniently using the app integrated into the scanner. L.I.S.-Hydraulics can also provide suitable scanner solutions for making status enquiries and ordering hose lines directly at the place of use of the systems and machines."}
        />


            <SectionHeader
                title={"THE COMBINATION OF SCANNER AND APPS CAN BE USED FOR DATA CAPTURE AND ORDERING"}
                subtitle={"The L.I.S.-Hydraulics scanner solutions combine the appropriate scanner technology with the corresponding L.I.S.-Hydraulics app. This saves time, is reliable and the product requirement is transmitted immediately to L.I.S.-Hydraulics."}
            />

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_01} left={false} /> <br /><br />
            </div>

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_02} /> <br /><br />
            </div>

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_03} left={false} /> <br /><br />
            </div>

            <SectionHeader
                title={"DIGITALLY CAPTURING AND ORDERING WITH L.I.S.-Hydraulics SCANNER SOLUTIONS"}
                subtitle={"Determining your goods and services needs with scanner solutions is quick and easy. Direct data transfer avoids errors and reduces processing costs."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"THE STEPS TO FINDING YOUR L.I.S.-Hydraulics SCANNER SOLUTION"}
                subtitle={"As a system partner, L.I.S.-Hydraulics offers scanner solutions as part of a consistent overall concept to ensure the efficiency of your merchandise management processes. Our experts support you from initial conception to the implementation of your customised scanner solution."}
            />


            {/* TODO: redbanner_carrousal */}

            <SectionHeader
                title={"ADDITIONAL DIGITAL SERVICES FROM L.I.S.-Hydraulics"}
                subtitle={""}
            />



            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgScanner_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Hose management with X-CODE Manager" desc="X-CODE Manager makes hose management simply more manageable because you have all the relevant information, such as inspection intervals, directly at your fingertips." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgScanner_Imagecard_02} learnMoreLink="/metal-hoses" title="Order fluid technology products quickly and easily in the L.I.S.-Hydraulics online shop" desc="Explore the L.I.S.-Hydraulics online shop with over 80,000 articles in the field of fluid technology and order the products you require with just a few mouse clicks." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgScanner_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Your digital hotline straight to L.I.S.-Hydraulics" desc="The digital interface with L.I.S.-Hydraulics via EDI or BMEcat enables efficient and error-free procurement processing." />
                    </Col>
                </Row>
            </Container>






        </div>
    )
}

export default Scannersolutions